<template>
  <div>
    <v-card>
      <v-card-text>
        <v-row class="mb-1">
            <v-col cols="2">
              <v-btn
                  class="btn-back elevation-0"
                  @click="backButton()"
              >
                <v-icon dark left> mdi-arrow-left </v-icon>Back
              </v-btn>
            </v-col>
          <v-col cols>
            <v-chip label :style="setPlanColor(plan.color_code)" class="mr-4">
              {{ plan.name }}
            </v-chip>
              <v-chip color="success" label>ຈຸດບໍລິການທັງໝົດ {{pagination.total}}</v-chip>
          </v-col>
        </v-row>
        <v-data-table
            :headers="headersDetail"
            :items="planDetail"
            :disable-pagination="true"
            hide-default-footer
            class="elevation-1"
            :loading="TableLoading"
            :disabled="TableLoading"
            loading-text="Loading... Please wait"
        >
          <template v-slot:item.home_image="{ item }">
            <v-avatar>
              <img
                  :src="item.home_image"
                  label
                  size="36"
                  @click="openImage(item.home_image)"
              >
            </v-avatar>
          </template>
          <template v-slot:item.created_at="{ item }">
            <div>
              {{ item.created_at | formatDate }}
            </div>
          </template>
          <template v-slot:item.invoice_date="{ item }">
            <div v-if="item.latest_invoice">
              {{ item.latest_invoice.invoice_date | formatDate}}
            </div>
          </template>
          <template v-slot:item.status="{ item }">
            <v-chip label :color="statusColor(item.status)">
              {{ item.status }}
            </v-chip>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="editItem(item.customer_id)">
              mdi-pencil
            </v-icon>
          </template>
          <!--Action -->
        </v-data-table>
        <br>
        <Pagination
            v-if="pagination.last_page > 1"
            :pagination="pagination"
            :offset="offset"
            @paginate="fetchData()"
        ></Pagination>
      </v-card-text>
    </v-card>

    <v-dialog
        v-model="viewModal"
        width="900px"
        max-with="1200px"
        loading
    >
      <template @close="close">
        <v-card>
          <v-card-text>
            <v-img :src="imageUrl">
            </v-img>
          </v-card-text>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>

import Pagination from "../../../plugins/pagination/pagination";
export default {
  data(){
    return {
      TableLoading: false,
      viewModal:false,
      imageUrl:"",
      planDetail: [],
      plan: {},
      //Pagination
      offset: 15,
      pagination: {},
      per_page: 80,
      headersDetail: [
        {text: "ລຳດັບ", value: "priority",align: "center"},
        {text: "ລະຫັດ", value: "customer_code",width: "150px"},
        {
          text: "ຊື່",
          align: "start",
          sortable: false,
          value: "name",
        },
        {text: "ບ້ານ", value: "villageName"},
        {text: "ເມືອງ", value: "districtName", sortable: false},
        {text: "ເບີໂທ", value: "phone", sortable: false},
        {text: "ສະຖານະ", value: "status", sortable: false},
        {text: "ຊື້ລ່າສຸດ", value: "invoice_date",width: "140px"},
        {text: "ເຂົ້າແຜນ", value: "created_at", sortable: false},
        {text: "ຮູບເຮືອນ", value: "home_image", sortable: false,width: "120px"},
        {text: "ແກ້ໄຂ", value: "actions", sortable: false},
      ],
    }
  },
  components: {
    Pagination,
  },
  metaInfo() {
    return {
      title: `ລາຍລະອຽດແຜນຈັດສົ່ງ`,
    }
  },
  methods:{
    openImage(url){
      if (url != null){
        this.viewModal = true;
        this.imageUrl = url;
      }
    },
    closeModal() {
      this.viewModal = false;
    },
    backButton(){
      this.$router.go(-1);
    },
    setPlanColor(value){
      let color = 'background-color:#' + value + ' !important;';
      return color;
    },
    editItem(id) {
      let routeData = this.$router.resolve({name: 'CustomerUpdate',params: {id:id}});
      window.open(routeData.href, '_blank');
    },
    statusColor(value){
      let color = 'background-color:#' + value + ' !important;';
      if(value == 'active') return 'success';
      else if(value == 'inactive') return 'error';
      return color;
    },
    fetchData() {
      this.TableLoading = true;
      this.$admin.get('route-plan/' + this.$route.params.id, {
        params: {
          page: this.pagination.current_page,
          per_page: this.per_page,
        }
      }).then (res => {
          this.planDetail = res.data.customer.data;
          this.pagination = res.data.customer;
          this.plan = res.data.plan;
          this.TableLoading = false;
      }).catch(() => {
        this.TableLoading = false;
        this.$store.commit("Toast_State", this.toast_error);
      });
    },
  },
  created() {
    this.fetchData();
  }
};

</script>

<style>
</style>