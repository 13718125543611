var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-text',[_c('v-row',{staticClass:"mb-1"},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{staticClass:"btn-back elevation-0",on:{"click":function($event){return _vm.backButton()}}},[_c('v-icon',{attrs:{"dark":"","left":""}},[_vm._v(" mdi-arrow-left ")]),_vm._v("Back ")],1)],1),_c('v-col',{attrs:{"cols":""}},[_c('v-chip',{staticClass:"mr-4",style:(_vm.setPlanColor(_vm.plan.color_code)),attrs:{"label":""}},[_vm._v(" "+_vm._s(_vm.plan.name)+" ")]),_c('v-chip',{attrs:{"color":"success","label":""}},[_vm._v("ຈຸດບໍລິການທັງໝົດ "+_vm._s(_vm.pagination.total))])],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersDetail,"items":_vm.planDetail,"disable-pagination":true,"hide-default-footer":"","loading":_vm.TableLoading,"disabled":_vm.TableLoading,"loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:"item.home_image",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',[_c('img',{attrs:{"src":item.home_image,"label":"","size":"36"},on:{"click":function($event){return _vm.openImage(item.home_image)}}})])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.created_at))+" ")])]}},{key:"item.invoice_date",fn:function(ref){
var item = ref.item;
return [(item.latest_invoice)?_c('div',[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.latest_invoice.invoice_date))+" ")]):_vm._e()]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","color":_vm.statusColor(item.status)}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item.customer_id)}}},[_vm._v(" mdi-pencil ")])]}}])}),_c('br'),(_vm.pagination.last_page > 1)?_c('Pagination',{attrs:{"pagination":_vm.pagination,"offset":_vm.offset},on:{"paginate":function($event){return _vm.fetchData()}}}):_vm._e()],1)],1),_c('v-dialog',{attrs:{"width":"900px","max-with":"1200px","loading":""},model:{value:(_vm.viewModal),callback:function ($$v) {_vm.viewModal=$$v},expression:"viewModal"}},[[_c('v-card',[_c('v-card-text',[_c('v-img',{attrs:{"src":_vm.imageUrl}})],1)],1)]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }